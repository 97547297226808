<template>
  <v-container
    fluid
    style="max-width: 1400px;"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-end"
          >
            <!--
              設定
            -->
            <v-menu
              :open-on-click="true"
              :close-on-content-click="false"
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  icon
                  text
                >
                  <v-icon>
                    mdi-cog
                  </v-icon>
                </v-btn>
              </template>
              <v-card class="ma-0 pa-0">
                <v-list>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch v-model="flag.masking" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        取得済み資格
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        日付情報を非表示
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch v-model="flag.hiddenMemo" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        メモを非表示
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <!--
              新規登録
            -->
            <v-menu
              :open-on-click="true"
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-4 font-weight-bold"
                  dark
                  depressed
                  color="success"
                >
                  New
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="mode='shikaku'; flag.dialog.addOrEdit=true">
                  <v-list-item-content>
                    <v-list-item-title>
                      取得済みの資格を登録
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="mode='study'; flag.dialog.addOrEdit=true">
                  <v-list-item-content>
                    <v-list-item-title>
                      勉強中の資格を登録
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="mode='kininaru'; flag.dialog.addOrEdit=true">
                  <v-list-item-content>
                    <v-list-item-title>
                      気になる資格を登録
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <!--
          資格一覧
        -->
        <v-card
          class="pa-0 my-4"
          flat
          outlined
        >
          <v-tabs
            v-model="tab"
            grow
            center-active
            show-arrows
            icons-and-text
          >
            <v-tab href="#tab-1">
              取得済み ({{ shikakuList.length }})
              <v-icon class="mr-2">mdi-badge-account-horizontal</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
              勉強中 ({{ studyList.length }})
              <v-icon class="mr-2">mdi-book-open-variant</v-icon>
            </v-tab>
            <v-tab href="#tab-3">
              気になる ({{ kininaruList.length }})
              <v-icon class="mr-2">mdi-star</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <template v-for="(item, index) in reversedShikakuList">
                <v-divider :key="`div-${index}`" />
                <v-list-item :key="`list-${index}`">
                  <v-list-item-avatar
                    left
                    color="#276fdc"
                  >
                    <v-icon dark>
                      mdi-badge-account-horizontal
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-h4"
                      v-text="item.name"
                    />
                    <!--
                      発行団体が設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.hakkoudantai">
                      {{ item.hakkoudantai }}
                    </v-list-item-subtitle>
                    <!--
                      取得日が設定されている場合のみ表示
                    -->
                    <v-list-item-title v-if="item.shutokubi">
                      <v-chip
                        x-small
                        label
                        class="mr-2"
                      >
                        取得日
                      </v-chip>
                      <span v-if="flag.masking">
                        XXXX-XX-XX
                      </span>
                      <span v-else>
                        {{ item.shutokubi }}
                      </span>
                    </v-list-item-title>
                    <!--
                      有効期限が設定されている場合のみ表示
                    -->
                    <v-list-item-title v-if="item.yuukoukigen">
                      <v-chip
                        x-small
                        label
                        class="mr-2"
                      >
                        有効期限
                      </v-chip>
                      <span v-if="flag.masking">
                        XXXX-XX-XX
                      </span>
                      <span v-else>
                        {{ item.yuukoukigen }}
                      </span>
                      <span
                        v-if="!flag.masking"
                        class="nobr red--text font-weight-bold ml-2"
                      >
                        {{ calcLeftDate(item.yuukoukigen) }}
                      </span>
                    </v-list-item-title>
                    <!--
                      更新期限が設定されている場合のみ表示
                    -->
                    <v-list-item-title v-if="item.koushinkigen">
                      <v-chip
                        x-small
                        label
                        class="mr-2"
                      >
                        更新期限
                      </v-chip>
                      <span v-if="flag.masking">
                        XXXX-XX-XX
                      </span>
                      <span v-else>
                        {{ item.koushinkigen }}
                      </span>
                      <span
                        v-if="!flag.masking"
                        class="nobr red--text font-weight-bold ml-2"
                      >
                        {{ calcLeftDate(item.koushinkigen) }}
                      </span>
                    </v-list-item-title>
                    <!--
                      参考サイトが設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.url">
                      <v-icon
                        size="small"
                        class="mr-1"
                      >
                        mdi-open-in-new
                      </v-icon>
                      <span
                        v-html="replaceUrl(item.url)"
                        class="font-weight-bold"
                      />
                    </v-list-item-subtitle>
                    <!--
                      メモが記載されている場合のみ表示
                    -->
                    <v-list-item-subtitle
                      v-if="item.memo && !flag.hiddenMemo"
                      v-html="formatMemo(item.memo)"
                      class="word-wrap mt-2"
                    />
                  </v-list-item-content>
                  <!--
                    Menuボタン
                  -->
                  <v-list-item-action>
                    <v-menu
                      :open-on-click="true"
                      bottom
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-horizontal
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="mode='shikaku'; editItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              編集
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="mode='shikaku'; deleteItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              削除
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <template v-for="(item, index) in reversedStudyList">
                <v-divider :key="`div-${index}`" />
                <v-list-item :key="`list-${index}`">
                  <v-list-item-avatar
                    left
                    color="secondary"
                  >
                    <v-icon dark>
                      mdi-book-open-variant
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-h4"
                      v-text="item.name"
                    />
                    <!--
                      発行団体が設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.hakkoudantai">
                      {{ item.hakkoudantai }}
                    </v-list-item-subtitle>
                    <!--
                      試験日が設定されている場合のみ表示
                    -->
                    <v-list-item-title v-if="item.shikenbi">
                      <v-chip
                        x-small
                        label
                        class="mr-2"
                      >
                        試験日
                      </v-chip>
                      {{ item.shikenbi }}
                      <span class="nobr red--text font-weight-bold ml-2">
                        {{ calcLeftDate(item.shikenbi) }}
                      </span>
                    </v-list-item-title>
                    <!--
                      結果発表が設定されている場合のみ表示
                    -->
                    <v-list-item-title v-if="item.kekkahappyou">
                      <v-chip
                        x-small
                        label
                        class="mr-2"
                      >
                        結果発表
                      </v-chip>
                      {{ item.kekkahappyou }}
                      <span class="nobr red--text font-weight-bold ml-2">
                        {{ calcLeftDate(item.kekkahappyou) }}
                      </span>
                    </v-list-item-title>
                    <!--
                      参考サイトが設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.url">
                      <v-icon
                        size="small"
                        class="mr-1"
                      >
                        mdi-open-in-new
                      </v-icon>
                      <span
                        v-html="replaceUrl(item.url)"
                        class="font-weight-bold"
                      />
                    </v-list-item-subtitle>
                    <!--
                      メモが記載されている場合のみ表示
                    -->
                    <v-list-item-subtitle
                      v-if="item.memo && !flag.hiddenMemo"
                      v-html="formatMemo(item.memo)"
                      class="word-wrap mt-2"
                    />
                  </v-list-item-content>
                  <!--
                    Menuボタン
                  -->
                  <v-list-item-action>
                    <v-menu
                      :open-on-click="true"
                      bottom
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-horizontal
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="mode='study'; editItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              編集
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="mode='study'; deleteItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              削除
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="changedToAcquired(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              取得済みに変更
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <template v-for="(item, index) in reversedKininaruList">
                <v-divider :key="`div-${index}`" />
                <v-list-item :key="`list-${index}`">
                  <v-list-item-avatar
                    left
                    color="warning"
                  >
                    <v-icon dark>
                      mdi-star
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-h4"
                      v-text="item.name"
                    />
                    <!--
                      発行団体が設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.hakkoudantai">
                      {{ item.hakkoudantai }}
                    </v-list-item-subtitle>
                    <!--
                      参考サイトが設定されている場合のみ表示
                    -->
                    <v-list-item-subtitle v-if="item.url">
                      <v-icon
                        size="small"
                        class="mr-1"
                      >
                        mdi-open-in-new
                      </v-icon>
                      <span
                        v-html="replaceUrl(item.url)"
                        class="font-weight-bold"
                      />
                    </v-list-item-subtitle>
                    <!--
                      メモが記載されている場合のみ表示
                    -->
                    <v-list-item-subtitle
                      v-if="item.memo && !flag.hiddenMemo"
                      v-html="formatMemo(item.memo)"
                      class="word-wrap mt-2"
                    />
                  </v-list-item-content>
                  <!--
                    Menuボタン
                  -->
                  <v-list-item-action>
                    <v-menu
                      :open-on-click="true"
                      bottom
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-dots-horizontal
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item @click="mode='kininaru'; editItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              編集
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="mode='kininaru'; deleteItem(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              削除
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="changedToStudy(item)">
                          <v-list-item-content>
                            <v-list-item-title>
                              勉強中に変更
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="flag.dialog.addOrEdit"
      width="500"
      persistent
    >
      <dialog-contents>
        <template slot="title">
          {{ formTitle }}
        </template>
        <template slot="text">
          <div v-show="mode==='shikaku'">
            <v-form
              ref="Form"
              class="mt-4"
            >
              <v-text-field
                v-model="shikaku.editedItem.name"
                label="資格の名称"
                :rules="[rules.required]"
              />
              <!--
                発行団体
              -->
              <v-checkbox
                v-model="flag.checkbox.hakkoudantai"
                @click="shikaku.editedItem.hakkoudantai=null"
                label="発行団体"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.hakkoudantai"
                v-model="shikaku.editedItem.hakkoudantai"
                label="発行団体"
              />
              <!--
                取得日
              -->
              <v-checkbox
                v-model="flag.checkbox.shutokubi"
                @click="shikaku.editedItem.shutokubi=null"
                label="取得日を設定"
                hide-details
                class="ma-0"
              />
              <v-menu
                v-if="flag.checkbox.shutokubi"
                v-model="flag.calendar.shutokubi"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="shikaku.editedItem.shutokubi"
                    label="取得日"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="shikaku.editedItem.shutokubi"
                  :active-picker.sync="activePicker"
                  locale="ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  class="ma-0"
                  no-title
                  @input="flag.calendar.shutokubi = false"
                />
              </v-menu>
              <!--
                有効期限
              -->
              <v-checkbox
                v-model="flag.checkbox.yuukoukigen"
                @click="shikaku.editedItem.yuukoukigen=null"
                label="有効期限を設定"
                hide-details
                class="ma-0"
              />
              <v-menu
                v-if="flag.checkbox.yuukoukigen"
                v-model="flag.calendar.yuukoukigen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="shikaku.editedItem.yuukoukigen"
                    label="有効期限"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="shikaku.editedItem.yuukoukigen"
                  :active-picker.sync="activePicker"
                  locale="ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  class="ma-0"
                  no-title
                  @input="flag.calendar.yuukoukigen = false"
                />
              </v-menu>
              <!--
                更新期限
              -->
              <v-checkbox
                v-model="flag.checkbox.koushinkigen"
                @click="shikaku.editedItem.koushinkigen=null"
                label="更新期限を設定"
                hide-details
                class="ma-0"
              />
              <v-menu
                v-if="flag.checkbox.koushinkigen"
                v-model="flag.calendar.koushinkigen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="shikaku.editedItem.koushinkigen"
                    label="更新期限"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="shikaku.editedItem.koushinkigen"
                  :active-picker.sync="activePicker"
                  locale="ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  class="ma-0"
                  no-title
                  @input="flag.calendar.koushinkigen = false"
                />
              </v-menu>
              <!--
                参考サイト
              -->
              <v-checkbox
                v-model="flag.checkbox.url"
                @click="shikaku.editedItem.url=null"
                label="参考サイト"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.url"
                v-model="shikaku.editedItem.url"
                label="参考サイト"
                :rules="[rules.validUrl]"
              />
              <v-textarea
                v-model="shikaku.editedItem.memo"
                class="mt-4"
                dense
                filled
                counter
                label="メモ"
                auto-grow
                :rules="[rules.maxMemoLen]"
              />
            </v-form>
          </div>
          <div v-show="mode==='study'">
            <v-form
              ref="FormStudy"
              class="mt-4"
            >
              <v-text-field
                v-model="study.editedItem.name"
                label="資格の名称"
                :rules="[rules.required]"
              />
              <!--
                発行団体
              -->
              <v-checkbox
                v-model="flag.checkbox.hakkoudantai"
                @click="study.editedItem.hakkoudantai=null"
                label="発行団体"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.hakkoudantai"
                v-model="study.editedItem.hakkoudantai"
                label="発行団体"
              />
              <!--
                試験日
              -->
              <v-checkbox
                v-model="flag.checkbox.shikenbi"
                @click="study.editedItem.shikenbi=null"
                label="試験日を設定"
                hide-details
                class="ma-0"
              />
              <v-menu
                v-if="flag.checkbox.shikenbi"
                v-model="flag.calendar.shikenbi"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="study.editedItem.shikenbi"
                    label="試験日"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="study.editedItem.shikenbi"
                  :active-picker.sync="activePicker"
                  locale="ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  class="ma-0"
                  no-title
                  @input="flag.calendar.shikenbi = false"
                />
              </v-menu>
              <!--
                結果発表
              -->
              <v-checkbox
                v-model="flag.checkbox.kekkahappyou"
                @click="study.editedItem.kekkahappyou=null"
                label="結果発表日を設定"
                hide-details
                class="ma-0"
              />
              <v-menu
                v-if="flag.checkbox.kekkahappyou"
                v-model="flag.calendar.kekkahappyou"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="study.editedItem.kekkahappyou"
                    label="結果発表"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="study.editedItem.kekkahappyou"
                  :active-picker.sync="activePicker"
                  locale="ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  class="ma-0"
                  no-title
                  @input="flag.calendar.kekkahappyou = false"
                />
              </v-menu>
              <!--
                参考サイト
              -->
              <v-checkbox
                v-model="flag.checkbox.url"
                @click="study.editedItem.url=null"
                label="参考サイト"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.url"
                v-model="study.editedItem.url"
                label="参考サイト"
                :rules="[rules.validUrl]"
              />
              <v-textarea
                v-model="study.editedItem.memo"
                class="mt-4"
                dense
                filled
                counter
                label="メモ"
                auto-grow
                :rules="[rules.maxMemoLen]"
              />
            </v-form>
          </div>
          <div v-show="mode==='kininaru'">
            <v-form
              ref="FormKininaru"
              class="mt-4"
            >
              <v-text-field
                v-model="kininaru.editedItem.name"
                label="資格の名称"
                :rules="[rules.required]"
              />
              <!--
                発行団体
              -->
              <v-checkbox
                v-model="flag.checkbox.hakkoudantai"
                @click="kininaru.editedItem.hakkoudantai=null"
                label="発行団体"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.hakkoudantai"
                v-model="kininaru.editedItem.hakkoudantai"
                label="発行団体"
              />
              <!--
                参考サイト
              -->
              <v-checkbox
                v-model="flag.checkbox.url"
                @click="kininaru.editedItem.url=null"
                label="参考サイト"
                hide-details
                class="ma-0"
              />
              <v-text-field
                v-if="flag.checkbox.url"
                v-model="kininaru.editedItem.url"
                label="参考サイト"
                :rules="[rules.validUrl]"
              />
              <v-textarea
                v-model="kininaru.editedItem.memo"
                class="mt-4"
                dense
                filled
                counter
                label="メモ"
                auto-grow
                :rules="[rules.maxMemoLen]"
              />
            </v-form>
          </div>
        </template>
        <template slot="button">
          <confirm-or-cancel-btn
            @saveFunc="save()"
            @closeFunc="close()"
          />
        </template>
      </dialog-contents>
    </v-dialog>
    <!--
      資格情報の削除
    -->
    <v-dialog
      v-model="flag.dialog.delete"
      width="400"
      persistent
    >
      <dialog-contents>
        <template slot="title">
          資格情報の削除
        </template>
        <template slot="text">
          選択した資格情報を本当に削除しますか？
        </template>
        <template slot="button">
          <delete-or-cancel-btn
            @deleteFunc="deleteItemConfirm()"
            @closeFunc="flag.dialog.delete=false"
          />
        </template>
      </dialog-contents>
    </v-dialog>
    <!--
      勉強中 -> 取得済みに変更
    -->
    <v-dialog
      v-model="flag.dialog.change"
      width="400"
      persistent
    >
      <dialog-contents>
        <template slot="title">
          取得済みに変更
        </template>
        <template slot="text">
          選択した資格情報を取得済みに変更しますか？
        </template>
        <template slot="button">
          <confirm-or-cancel-btn
            @saveFunc="changedToAcquiredConfirm()"
            @closeFunc="flag.dialog.change=false"
          />
        </template>
      </dialog-contents>
    </v-dialog>
    <!--
      気になる -> 勉強中 に変更
    -->
    <v-dialog
      v-model="flag.dialog.changeStudy"
      width="400"
      persistent
    >
      <dialog-contents>
        <template slot="title">
          勉強中に変更
        </template>
        <template slot="text">
          選択した資格情報を勉強中に変更しますか？
        </template>
        <template slot="button">
          <confirm-or-cancel-btn
            @saveFunc="changedToStudyConfirm()"
            @closeFunc="flag.dialog.changeStudy=false"
          />
        </template>
      </dialog-contents>
    </v-dialog>
    <v-snackbar
      v-model="flag.snackbar"
      multi-line
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="flag.snackbar=false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import moment from 'moment'
  import rulesMixin from './mixin/rules'
  import { funcSetDataToFirebase } from '../../functions/fb-modules'
  export default {
    components: {
      ConfirmOrCancelBtn: () => import('./components/ConfirmOrCancelBtn'),
      DeleteOrCancelBtn: () => import('./components/DeleteOrCancelBtn'),
      DialogContents: () => import('./components/DialogContents'),
    },
    mixins: [
      rulesMixin,
    ],
    data: () => ({
      mode: 'shikaku',
      tab: null,
      editedIndex: -1,
      shikakuList: [],
      studyList: [],
      kininaruList: [],
      taskList: [],
      flag: {
        dialog: {
          addOrEdit: false,
          delete: false,
          change: false,
          changeStudy: false,
        },
        checkbox: {
          shutokubi: false,
          yuukoukigen: false,
          koushinkigen: false,
          shikenbi: false,
          kekkahappyou: false,
          url: false,
          hakkoudantai: false,
        },
        calendar: {
          shutokubi: false,
          yuukoukigen: false,
          koushinkigen: false,
          shikenbi: false,
          kekkahappyou: false,
        },
        show: {
          shikakuList: true,
          studyList: true,
          kininaruList: true,
        },
        masking: false,
        hiddenMemo: false,
        snackbar: false,
      },
      shikaku: {
        editedItem: {
          id: '',
          name: '',
          shutokubi: null,
          yuukoukigen: null,
          koushinkigen: null,
          memo: '',
          url: '',
          hakkoudantai: '',
        },
        defaultItem: {
          id: '',
          name: '',
          shutokubi: null,
          yuukoukigen: null,
          koushinkigen: null,
          memo: '',
          url: '',
          hakkoudantai: '',
        },
      },
      study: {
        editedItem: {
          id: '',
          name: '',
          shikenbi: null,
          kekkahappyou: null,
          memo: '',
          url: '',
          hakkoudantai: '',
        },
        defaultItem: {
          id: '',
          name: '',
          shikenbi: null,
          kekkahappyou: null,
          memo: '',
          url: '',
          hakkoudantai: '',
        },
      },
      kininaru: {
        editedItem: {
          id: '',
          name: '',
          memo: '',
          url: '',
          hakkoudantai: '',
        },
        defaultItem: {
          id: '',
          name: '',
          memo: '',
          url: '',
          hakkoudantai: '',
        },
      },
      snackbarText: '',
      activePicker: null,
    }),
    computed: {
      formTitle () {
        if (this.editedIndex === -1) {
          return '資格情報の登録'
        }
        return '資格情報の編集'
      },
      reversedShikakuList () {
        return this.shikakuList.slice().reverse()
      },
      reversedStudyList () {
        return this.studyList.slice().reverse()
      },
      reversedKininaruList () {
        return this.kininaruList.slice().reverse()
      },
      sortedShikakuList () {
        return this.shikakuList.slice().sort((a, b) => {
          // 空欄を最後尾に配置
          if (!a.shutokubi && b.shutokubi) return 1
          if (a.shutokubi && !b.shutokubi) return -1
          // 通常の比較
          return (a.shutokubi < b.shutokubi) ? -1 : (a.shutokubi > b.shutokubi) ? 1 : 0
        })
      },
      sortedStudyList () {
        return this.studyList.slice().sort((a, b) => {
          // 空欄を最後尾に配置
          if (!a.shikenbi && b.shikenbi) return 1
          if (a.shikenbi && !b.shikenbi) return -1
          // 通常の比較
          return (a.shikenbi < b.shikenbi) ? -1 : (a.shikenbi > b.shikenbi) ? 1 : 0
        })
      },
      showListTitle () {
        if (this.flag.show.shikakuList === true && this.flag.show.studyList === true) {
          return 'すべて'
        } else if (this.flag.show.shikakuList === true) {
          return '取得済み'
        } else if (this.flag.show.studyList === true) {
          return '勉強中'
        } else if (this.flag.show.kininaruList === true) {
          return '気になる'
        }
        return ''
      },
    },
    created () {
      this.$vuetify.theme.dark = this.$store.state.darkMode
      this.shikakuList = this.$store.state.user.shikakuList
      this.studyList = this.$store.state.user.studyList
      this.kininaruList = this.$store.state.user.kininaruList
      this.taskList = this.$store.state.user.taskList
    },
    watch: {
      'flag.calendar.shutokubi' (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      'flag.calendar.yuukoukigen' (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      'flag.calendar.koushinkigen' (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      'flag.calendar.shikenbi' (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      'flag.calendar.kekkahappyou' (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    methods: {
      /*
      */
      resetAllCheckbox () {
        this.flag.checkbox.shutokubi = false
        this.flag.checkbox.yuukoukigen = false
        this.flag.checkbox.koushinkigen = false
        this.flag.checkbox.shikenbi = false
        this.flag.checkbox.kekkahappyou = false
        this.flag.checkbox.url = false
        this.flag.checkbox.hakkoudantai = false
      },
      resetAllFormValidation () {
        this.$refs.Form.resetValidation()
        this.$refs.FormStudy.resetValidation()
        this.$refs.FormKininaru.resetValidation()
      },
      resetEditedItem () {
        this.shikaku.editedItem = Object.assign({}, this.shikaku.defaultItem)
        this.study.editedItem = Object.assign({}, this.study.defaultItem)
        this.kininaru.editedItem = Object.assign({}, this.kininaru.defaultItem)
        this.editedIndex = -1
      },
      /*
      */
      setShikakuItem (item) {
        this.editedIndex = this.shikakuList.indexOf(item)
        this.shikaku.editedItem = Object.assign({}, item)
      },
      setStudyItem (item) {
        this.editedIndex = this.studyList.indexOf(item)
        this.study.editedItem = Object.assign({}, item)
      },
      setKininaruItem (item) {
        this.editedIndex = this.kininaruList.indexOf(item)
        this.kininaru.editedItem = Object.assign({}, item)
      },
      /*
      */
      editItem (item) {
        if (this.mode === 'study') {
          this.setStudyItem(item)
          /*
             値が設定済みの場合
             チェックボックスに印を入れた状態で
             ダイアログをオープンする
          */
          if (this.study.editedItem.shikenbi) {
            this.flag.checkbox.shikenbi = true
          }
          if (this.study.editedItem.kekkahappyou) {
            this.flag.checkbox.kekkahappyou = true
          }
          if (this.study.editedItem.url) {
            this.flag.checkbox.url = true
          }
          if (this.study.editedItem.hakkoudantai) {
            this.flag.checkbox.hakkoudantai = true
          }
        } else if (this.mode === 'kininaru') {
          this.setKininaruItem(item)
          /*
             値が設定済みの場合
             チェックボックスに印を入れた状態で
             ダイアログをオープンする
          */
          if (this.kininaru.editedItem.url) {
            this.flag.checkbox.url = true
          }
          if (this.kininaru.editedItem.hakkoudantai) {
            this.flag.checkbox.hakkoudantai = true
          }
        } else {
          this.setShikakuItem(item)
          /*
             値が設定済みの場合
             チェックボックスに印を入れた状態で
             ダイアログをオープンする
          */
          if (this.shikaku.editedItem.shutokubi) {
            this.flag.checkbox.shutokubi = true
          }
          if (this.shikaku.editedItem.yuukoukigen) {
            this.flag.checkbox.yuukoukigen = true
          }
          if (this.shikaku.editedItem.koushinkigen) {
            this.flag.checkbox.koushinkigen = true
          }
          if (this.shikaku.editedItem.url) {
            this.flag.checkbox.url = true
          }
          if (this.shikaku.editedItem.hakkoudantai) {
            this.flag.checkbox.hakkoudantai = true
          }
        }
        this.flag.dialog.addOrEdit = true
      },
      deleteItem (item) {
        if (this.mode === 'study') {
          this.setStudyItem(item)
        } else if (this.mode === 'kininaru') {
          this.setKininaruItem(item)
        } else {
          this.setShikakuItem(item)
        }
        this.flag.dialog.delete = true
      },
      deleteItemConfirm () {
        if (this.mode === 'study') {
          /* 勉強中資格に紐付く進捗情報も削除 */
          /*
          const studyId = this.studyList[`${this.editedIndex}`].id
          var dstTaskList = []
          for (const task of this.taskList) {
            if (task.parentId !== studyId) {
              dstTaskList.push(task)
            }
          }
          this.$store.commit('UPDATE_STORE_TASKLIST', { taskList: dstTaskList })
          */
          /* 勉強中資格情報を削除 */
          this.studyList.splice(this.editedIndex, 1)
          this.$store.commit('UPDATE_STORE_STUDYLIST', { studyList: this.studyList })
        } else if (this.mode === 'kininaru') {
          this.kininaruList.splice(this.editedIndex, 1)
          this.$store.commit('UPDATE_STORE_KININARULIST', { kininaruList: this.kininaruList })
        } else {
          this.shikakuList.splice(this.editedIndex, 1)
          this.$store.commit('UPDATE_STORE_SHIKAKULIST', { shikakuList: this.shikakuList })
        }
        /* ログイン済みユーザの場合Firebaseのデータを更新 */
        if (this.$store.state.user.uid) {
          funcSetDataToFirebase()
        }
        this.flag.dialog.delete = false
        this.close()
        this.showSnackbar('資格情報を削除しました')
      },
      close () {
        this.flag.dialog.addOrEdit = false
        this.$nextTick(() => {
          this.resetAllFormValidation()
          this.resetAllCheckbox()
          this.resetEditedItem()
        })
      },
      save () {
        if (this.mode === 'study') {
          if (!this.$refs.FormStudy.validate()) return 1
          if (this.editedIndex > -1) {
            if (!this.study.editedItem.id) {
              this.study.editedItem.id = uuidv4()
            }
            Object.assign(this.studyList[this.editedIndex], this.study.editedItem)
          } else {
            this.study.editedItem.id = uuidv4()
            this.studyList.push(this.study.editedItem)
          }
          this.$store.commit('UPDATE_STORE_STUDYLIST', { studyList: this.studyList })
        } else if (this.mode === 'kininaru') {
          if (!this.$refs.FormKininaru.validate()) return 1
          if (this.editedIndex > -1) {
            if (!this.kininaru.editedItem.id) {
              this.kininaru.editedItem.id = uuidv4()
            }
            Object.assign(this.kininaruList[this.editedIndex], this.kininaru.editedItem)
          } else {
            this.kininaru.editedItem.id = uuidv4()
            this.kininaruList.push(this.kininaru.editedItem)
          }
          this.$store.commit('UPDATE_STORE_KININARULIST', { kininaruList: this.kininaruList })
        } else {
          if (!this.$refs.Form.validate()) return 1
          if (this.editedIndex > -1) {
            if (!this.shikaku.editedItem.id) {
              this.shikaku.editedItem.id = uuidv4()
            }
            Object.assign(this.shikakuList[this.editedIndex], this.shikaku.editedItem)
          } else {
            this.shikaku.editedItem.id = uuidv4()
            this.shikakuList.push(this.shikaku.editedItem)
          }
          this.$store.commit('UPDATE_STORE_SHIKAKULIST', { shikakuList: this.shikakuList })
        }
        /* ログイン済みユーザの場合Firebaseのデータを更新 */
        if (this.$store.state.user.uid) {
          funcSetDataToFirebase()
        }
        this.close()
        this.showSnackbar('資格情報を保存しました')
      },
      calcLeftDate (target) {
        const today = new Date()
        const targetDate = new Date(target + 'T23:59:59')
        var leftSecond = moment(targetDate).diff(today, 'seconds')
        if (leftSecond < 0) {
          return '終了'
        } else {
          var leftDay = moment(targetDate).diff(today, 'days')
          if (leftDay > 100) {
            return ''
          } else if (leftDay > 0) {
            return 'あと ' + leftDay + ' 日'
          }
          return '当日'
        }
      },
      changedToAcquired (item) {
        this.editedIndex = this.studyList.indexOf(item)
        this.flag.dialog.change = true
      },
      changedToStudy (item) {
        this.editedIndex = this.kininaruList.indexOf(item)
        this.flag.dialog.changeStudy = true
      },
      changedToAcquiredConfirm () {
        this.shikaku.editedItem.id = this.studyList[`${this.editedIndex}`].id
        this.shikaku.editedItem.name = this.studyList[`${this.editedIndex}`].name
        this.shikaku.editedItem.memo = this.studyList[`${this.editedIndex}`].memo
        this.shikaku.editedItem.url = this.studyList[`${this.editedIndex}`].url
        this.shikaku.editedItem.hakkoudantai = this.studyList[`${this.editedIndex}`].hakkoudantai
        this.shikakuList.push(this.shikaku.editedItem)
        this.$store.commit('UPDATE_STORE_SHIKAKULIST', { shikakuList: this.shikakuList })
        /* 勉強中資格に紐付く進捗情報も削除 */
        /*
        var dstTaskList = []
        for (const task of this.taskList) {
          if (task.parentId !== this.shikaku.editedItem.id) {
            dstTaskList.push(task)
          }
        }
        this.taskList = dstTaskList
        this.$store.commit('UPDATE_STORE_TASKLIST', { taskList: dstTaskList })
        */
        /* 勉強中資格情報を削除 */
        this.studyList.splice(this.editedIndex, 1)
        this.$store.commit('UPDATE_STORE_STUDYLIST', { studyList: this.studyList })
        /* ログイン済みユーザの場合Firebaseのデータを更新 */
        if (this.$store.state.user.uid) {
          funcSetDataToFirebase()
        }
        this.resetEditedItem()
        this.flag.dialog.change = false
        this.showSnackbar('資格情報を変更しました')
      },
      changedToStudyConfirm () {
        this.study.editedItem.id = this.kininaruList[`${this.editedIndex}`].id
        this.study.editedItem.name = this.kininaruList[`${this.editedIndex}`].name
        this.study.editedItem.memo = this.kininaruList[`${this.editedIndex}`].memo
        this.study.editedItem.url = this.kininaruList[`${this.editedIndex}`].url
        this.study.editedItem.hakkoudantai = this.kininaruList[`${this.editedIndex}`].hakkoudantai
        this.studyList.push(this.study.editedItem)
        this.$store.commit('UPDATE_STORE_STUDYLIST', { studyList: this.studyList })
        /* 気になる資格情報を削除 */
        this.kininaruList.splice(this.editedIndex, 1)
        this.$store.commit('UPDATE_STORE_KININARULIST', { kininaruList: this.kininaruList })
        /* ログイン済みユーザの場合Firebaseのデータを更新 */
        if (this.$store.state.user.uid) {
          funcSetDataToFirebase()
        }
        this.resetEditedItem()
        this.flag.dialog.changeStudy = false
        this.showSnackbar('資格情報を変更しました')
      },
      formatMemo (memo) {
        return memo.replace(/(?:\r\n|\r|\n)/g, '<br>')
      },
      replaceUrl (url) {
        return '<a target=_blank class="text-decoration-none link" href=' + url + '>参考サイトを表示</a>'
      },
      showSnackbar (text) {
        this.snackbarText = text
        this.flag.snackbar = true
      },
    },
  }
</script>

<style>
.nobr {
  display: inline-block;
}
.word-wrap {
  white-space: normal;
  word-wrap: break-word;
}
.link {
  color: #1558d6 !important;
}

/* Smart Phone */
@media screen and (max-width: 479px) {
  h2 {
     font-size: 36px;
  }
}
</style>
